"use strict";

 angular.module('postCenter.environment', [])

.constant('BASE_URL', 'https://api-cluster.adere.so')

.constant('GA', 'G-LWFQLH0XFH')

.constant('FB_APP_ID', '845052388872761')

.constant('FB_API_VERSION', 'v18.0')

.constant('RECAPTCHA_CHECKBOX_TOKEN', '6LeHdgUeAAAAAAow_1q3BnG1IiGMg-XQ1yrbNzxM')

.constant('RECAPTCHA_INVISIBLE_TOKEN', '6Ldr0iMeAAAAAGVqSnUKm7b-n3iEjXoz01SNDRj4')

.constant('OLARK_TOKEN', '6791-867-10-4388')

.constant('INTERCOM_TOKEN', 'fecvyd9h')

.constant('SENTRY_DSN', 'https://f5f94ccccabcd338f81ea2e10d025da8@sentry.adere.so/5')

.constant('GOOGLE_API_KEY', 'AIzaSyDc2qSlFOX6lSMTHES8b75A5SpjxgrexW0')

.constant('TIEMZONE_DB_KEY', 'OVA16C2VQZP8')

.constant('SLACK_CLIENT_ID', '5014892154.314060371398')

.constant('TALKCENTER_TOKEN', 'bcded7c45fef108c4ab0fb5992a74163')

.constant('FACEBOOK_PERMISSIONS', 'pages_read_engagement::pages_manage_metadata::pages_messaging::instagram_basic::instagram_manage_messages::instagram_manage_comments::pages_read_user_content::pages_manage_engagement::pages_manage_posts::business_management::pages_show_list')

.constant('FRONTEND3_URL', '/frontend3/#/')

.constant('FRONTEND_VUE_URL', '/vue/#')

.constant('ENV', 'prod')

.constant('FIREBASE_CONFIG', {apiKey:'AIzaSyClcuYH2AJcIeKlO1sED02-kmmM0IdM76k',authDomain:'omega-math-610.firebaseapp.com',databaseURL:'https://omega-math-610.firebaseio.com',projectId:'omega-math-610',storageBucket:'omega-math-610.appspot.com',messagingSenderId:'584577536823',appId:'1:584577536823:web:a162a2d6512865d1ac00b2',measurementId:'G-PKJLBTDKH8'})

.constant('WOOTRIC_TOKEN', 'NPS-1294ea21')

.constant('SSO_LOGIN', '')

;